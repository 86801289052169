import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  group: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "100%",
  },
  groupLabel: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.light,
  },
}));

export default function FormGroup({ children, label }) {
  const classes = useStyles();

  return (
    <Paper className={classes.group}>
      {label ? (
        <Typography variant="subtitle2" className={classes.groupLabel}>
          {label}
        </Typography>
      ) : null}
      {children}
    </Paper>
  );
}
