import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "@material-ui/core";
import BackgroundImage from "gatsby-background-image";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    minHeight: "100vh",
  },
  content: {
    paddingTop: (props) => props.paddingTop || "25vh",
  },
}));

const Layout = (props) => {
  const { children, data } = props;
  const classes = useStyles(props);

  const { image } = useStaticQuery(
    graphql`
      query BackgroundImage {
        image: file(name: { eq: "daniil-silantev-nBuiLbz_j4A-unsplash" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <BackgroundImage fluid={image.childImageSharp.fluid}>
      <div className={classes.wrapper}>
        <Container maxWidth="md" className={classes.content}>
          {children}
        </Container>
      </div>
    </BackgroundImage>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
